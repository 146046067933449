import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './golden-account-long.png';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';


import IrsTable from './irs/2024.json';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://golden-account.com/">
        Golden Account
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Simulator() {

  const [insurance] = useState(600);
  const [accounting] = useState(2400);
  const [peopleExpenses, setPeopleExpenses] = useState(0);
  const [netIncomeYear, setNetIncomeYear] = useState(0);
  const [netIncomeMonth, setNetIncomeMonth] = useState(0);
  const [exerciseResults, setExerciseResults] = useState(0);
  const [totalNetYear, setTotalNetYear] = useState(0);
  const [totalNetIn14, setTotalNetIn14] = useState(0);

  const [open, setOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    severity: 'success'
  })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      open: false
    })
  }
  const handleFormSubmit = async e => {
    e.preventDefault();
    try {

      if (!input.name) {
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Nome em falta.'
        });
        return;
      }

      if (!input.email) {
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Email em falta.'
        });
        return;
      }

      const body = Object.fromEntries(
        Object.entries(input).map(([key, value]) =>
          [`$${key}`, value]
        )
      )

      const res = await fetch('https://api.staticforms.xyz/submit', {
        method: 'POST',
        body: JSON.stringify(
          {
            ...body,
            subject: 'StaticForms - Simulador Unipessoal',
            honeypot: '', // if any value received in this field, form submission will be ignored.
            accessKey: '539b49fc-c145-473f-ba4b-ba7de3ff5f41' // get your access key from https://www.staticforms.xyz
          }),
        headers: { 'Content-Type': 'application/json' }
      });

      const json = await res.json();

      if (json.success) {
        setSnackbar({
          open: true,
          severity: 'success',
          message: 'Obrigado por entrar em contacto. Prometemos ser breves.'
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          message: json.message
        });
      }
    } catch (e) {
      console.log('An error occurred', e);
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'Ocorreu um erro ao submeter o formulário. Pedimos desculpa. Entretanto pedimos para entrar em contacto via info@golden-account.com.'
      });
    }
    setOpen(false);
  };


  const [input, setInput] = useState({
    dependents: 0,
    irsTax: 0,
    grossPerMonth: 760,
    perDiem: 100,
    maritalStatus: 'single',
    dailyAllowence: 4.77,
    monthlyAllowenceType: 'money',
    shouldShowResult: false,
    name: '',
    email: ''
  });

  const maritalStatusToIrsTable = (maritalStatus, dependents) => {
    if (maritalStatus === 'single')
      return dependents !== 0 ? 'SOLD' : 'SOLCAS2';
    if (maritalStatus === 'married1')
      return 'CAS1';
    if (maritalStatus === 'married2')
      return 'SOLCAS2';
  };

  const taxTable = maritalStatusToIrsTable(input.maritalStatus, input.dependents);

  let tax = IrsTable[taxTable].find(element => {
    return element.limite > input.grossPerMonth;
  });

  if (!tax) {
    tax = IrsTable[taxTable][IrsTable[taxTable].length - 1];
  }

  let parcelaAbater = tax.parcela_abater;
  if (tax.var1 !== 0 && tax.var2 !== 0) {
    parcelaAbater = (tax.parcela_abater / 100) * tax.var1 * (tax.var2 - input.grossPerMonth);
  }

  const parcelaAbaterIRS = parcelaAbater + (tax.adicional * input.dependents);

  const retencaoIRS = (Math.max((input.grossPerMonth * tax.maximo) - parcelaAbaterIRS, 0)).toFixed(2);
  const irsTax = ((retencaoIRS / input.grossPerMonth) * 100).toFixed(2);


  const handleSubmit = (event) => {
    event.preventDefault();

    if (input.monthlyAllowenceType === 'card' && input.dailyAllowence > 7.63)
      setInput({ ...input, dailyAllowence: 7.63 });
    if (input.monthlyAllowenceType === 'money' && input.dailyAllowence > 4.77)
      setInput({ ...input, dailyAllowence: 4.77 });


    const monthlyAllowence = input.dailyAllowence * 21;
    const anualGrossIncome = input.grossPerMonth * 14;
    const employeerTaxSegSocial = 0.2375;
    const peopleCosts = anualGrossIncome + anualGrossIncome * employeerTaxSegSocial + monthlyAllowence * 11;
    const totalSpent = insurance + accounting + peopleCosts;

    const employeeSegSocialTax = 0.11;
    const netIncome = anualGrossIncome - (retencaoIRS * 14) - (anualGrossIncome * employeeSegSocialTax) + (monthlyAllowence * 11);
    const netIncomeMonth = input.grossPerMonth - retencaoIRS - (input.grossPerMonth * employeeSegSocialTax) + monthlyAllowence;

    setPeopleExpenses(peopleCosts.toFixed(2));
    setNetIncomeYear(netIncome.toFixed(2));
    setNetIncomeMonth(netIncomeMonth.toFixed(2));

    const totalIncome = input.perDiem * 21 * 11;

    const exerciseTotalResult = (totalIncome - totalSpent);
    const irc17 = exerciseTotalResult > 25000 ? 25000 * 0.17 : exerciseTotalResult * 0.17;
    const irc21 = exerciseTotalResult > 25000 ? (exerciseTotalResult - 25000) * 0.17 : 0;
    const derrama = exerciseTotalResult * 0.015;
    const exerciseResultAfterTaxes = exerciseTotalResult - derrama - irc17 - irc21;

    console.log('IRC 17%: ' + irc17);
    console.log('IRC 21%: ' + irc21);
    console.log('Derrama (1.5%): ' + derrama);

    setExerciseResults(exerciseResultAfterTaxes.toFixed(2));
    const taxGains = 0.28;
    const taxGainsValue = exerciseTotalResult * taxGains;
    const totalNet = (exerciseTotalResult - taxGainsValue) + netIncome;
    console.log('Imposto sobre os lucros (28%): ' + taxGainsValue)
    setTotalNetYear(totalNet.toFixed(2));
    setTotalNetIn14((totalNet / 14).toFixed(2));

    setInput({ ...input, shouldShowResult: true });

  };

  let result = <></>;

  if (input.shouldShowResult)
    result = <><Grid container spacing={2} visibility={false}>
      <Grid item xs={12}>
        <Typography component="h2">
          Despesas / Ano
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="insurance"
          label="Seguros e Criação Empresa (valores ilustrativos)"
          name="insurance"
          disabled
          type="number"
          defaultValue={insurance}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="accounting"
          label="Contabilidade (valores ilustrativos)"
          name="accounting"
          disabled
          type="number"
          defaultValue={accounting}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="peopleCosts"
          label="Despesas com pessoal"
          name="peopleCosts"
          disabled
          type="number"
          value={peopleExpenses}
        />
      </Grid>
    </Grid>
      <Grid container spacing={2} visibility={false}>
        <Grid item xs={12}>
          <Typography component="h2">
            Receitas
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Vencimento Liquido Anual"
            disabled
            type="number"
            value={netIncomeYear}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Vencimento Liquido Mensal (com subsídio alimentação)"
            disabled
            type="number"
            value={netIncomeMonth}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Resultado do Exercício (depois de IRC e Derrama)"
            disabled
            type="number"
            value={exerciseResults}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Rendimento Liquido Anual (Vencimento Liquido + Resultado do Exercício após impostos)"
            disabled
            type="number"
            value={totalNetYear}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Rendimento Liquido mensal (x14)"
            disabled
            type="number"
            value={totalNetIn14}
          />
        </Grid>
        <Grid item xs={12}>
          <Alert severity="warning">
            <AlertTitle>Importante</AlertTitle>
            Esta simulação não é vinculativa. <br /><strong>Entra em contacto conosco</strong> para uma simulação mais detalhada.
          </Alert>
        </Grid>


        <Grid item xs={12}>
          <Alert severity="info">
            <AlertTitle>Importante</AlertTitle>
            Uma Sociedade Unipessoal obriga a contratação de um contabilista certificado.<br /><strong>Entra em contacto conosco</strong> para uma proposta de serviços (<a href="mailto:info@golden-account.com" alt="Golden Account">info@golden-account.com</a>).
          </Alert>
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={handleClickOpen}
        color="success">
        Queres receber uma simulação detalhada?
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Receber mais informação sobre Unipessoal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, deixa o teu email para receber uma simulação detalhada.
            O e-mail será utilizado unica e exclusivamente para este efeito.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome"
            type="text"
            required
            fullWidth
            variant="standard"
            value={input.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInput(
                {
                  ...input,
                  name: event.target.value,
                });
            }}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            type="email"
            required
            fullWidth
            variant="standard"
            value={input.email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInput(
                {
                  ...input,
                  email: event.target.value,
                });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit" onClick={handleFormSubmit}>Pedir informação</Button>
        </DialogActions>
      </Dialog>

    </>

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>

        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box>
            <a href="https://golden-account.com" alt="Golden Account"><img src={logo} width={300} alt="Golden Account" /></a>
          </Box>

          <Typography component="h1" variant="h5">
            Queres criar uma Unipessoal mas não sabes qual o rendimento liquido que vais ter?
          </Typography>
          <Typography variant="subtitle1" gutterBottom component="div">

            Utiliza o nosso simulador personalizado para estimar o rendimento líquido da tua Unipessoal, equiparando-o a um salário convencional.
            Conheçe o potencial da tua empresa, faz escolhas informadas e toma decisões financeiras com confiança!

          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="perDiem"
                  required
                  fullWidth
                  id="perDiem"
                  label="Qual o valor/dia que vais faturar?"
                  autoFocus
                  type="number"
                  value={input.perDiem}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setInput(
                      {
                        ...input,
                        perDiem: event.target.value,
                        shouldShowResult: false
                      });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="grossPerMonth"
                  label="Qual a remuneração base mensal que desejas (enquanto funcionário da unipessoal)?"
                  name="grossPerMonth"
                  type="number"
                  value={input.grossPerMonth}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setInput(
                      {
                        ...input,
                        grossPerMonth: event.target.value,
                        shouldShowResult: false
                      });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Situação</InputLabel>
                  <Select
                    id="maritalStatus"
                    label="Situação"
                    value={input.maritalStatus}
                    onChange={(event: SelectChangeEvent<typeof maritalStatus>) => {
                      const {
                        target: { value },
                      } = event;
                      setInput({ ...input, maritalStatus: value, shouldShowResult: false });
                    }}
                  >
                    <MenuItem value={"single"}>Não casado</MenuItem>
                    <MenuItem value={"married1"}>Casado, 1 titular</MenuItem>
                    <MenuItem value={"married2"}>Casado, 2 titulares</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>N. Dependentes</InputLabel>
                  <Select
                    id="dependents"
                    label="N. Dependentes"
                    value={input.dependents}
                    onChange={(event: SelectChangeEvent<typeof dependents>) => {
                      const {
                        target: { value },
                      } = event;
                      setInput(
                        {
                          ...input,
                          dependents: value,
                          shouldShowResult: false
                        });
                    }}
                  >
                    <MenuItem value={"0"}>0</MenuItem>
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                    <MenuItem value={"6"}>6</MenuItem>
                    <MenuItem value={"7"}>7</MenuItem>
                    <MenuItem value={"8"}>8</MenuItem>
                    <MenuItem value={"9"}>9</MenuItem>
                    <MenuItem value={"10"}>10</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Tooltip title="Taxa de retenção na fonte calculada com base na tabela de IRS de 2024 (novembro e dezembro)" arrow>
                  <TextField
                    fullWidth
                    id="irsTax"
                    label="Taxa de retenção IRS (rendimentos)"
                    name="irsTax"
                    type="number"
                    disabled
                    value={irsTax}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Sub. refeição</InputLabel>
                  <Select
                    id="monthlyAllowenceType"
                    label="Sub. refeição"
                    value={input.monthlyAllowenceType}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInput({ ...input, monthlyAllowenceType: event.target.value, shouldShowResult: false })}
                  >
                    <MenuItem value={"money"}>Dinheiro</MenuItem>
                    <MenuItem value={"card"}>Cartão</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  fullWidth
                  id="dailyAllowence"
                  label={`Qual o sub. refeição diário (limite max. isenção: ${(input.monthlyAllowenceType === 'money' ? "4.77" : "7.63")}€)?`}
                  name="dailyAllowence"
                  type="number"
                  value={input.dailyAllowence}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInput({ ...input, dailyAllowence: event.target.value, shouldShowResult: false })}
                />
              </Grid>

            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Simula agora!
            </Button>
            {
              result
            }

          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}